import * as React from 'react';
import styled from 'styled-components';
import { Buttons } from '../../Common/Styles/Interactions';

interface IContactCtaProps {
    className?: string;
    title: string;
    summary:string;
}

const StyledContactCta = styled.div`
    background-color: var(--nearlyNavy);
    box-shadow: 4px 4px 6px #1D23401A;
    padding: 32px 24px;
    @media (min-width: ${props => props.theme.screensizes.medium}) {
        padding: 32px;
    }

    h3 {
        color: var(--meaningfulMarigold);
        text-transform:uppercase;
        margin-bottom:20px;
    }
    p {
        color: #fff;
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            max-width: 90%;
            margin-bottom: 30px;
        }
    }
    [class^="Primary__Button"] {
        max-width: 100%;
    }
`;
const StyledTitle = styled.h3`
`;

const ContactCta: React.FC<IContactCtaProps> = (props) => {
    return (
        <StyledContactCta className={props.className ? `${props.className} contact-cta` : 'contact-cta'}>
           <StyledTitle>{props.title}</StyledTitle>
           <p className="content-summary">{props.summary}</p>
           <Buttons.Primary text={ 'Contact Us' } href={ '/contact-us/ ' } target={ '_self' } />
        </StyledContactCta>
    );
}

export default ContactCta;