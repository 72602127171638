import * as React from 'react';
import styled from 'styled-components'
import { IParagraphProps } from './types';

const Block = styled.article`
    h3 {
        font-size: 32px;
        line-height: 40px;
        font-weight: 300;
    }

    p {
        margin: 16px 0;
        font-weight: 300;
        font-style: normal;
        color: ${props => props.theme.colors.greatlyGrey};
    }

    > :first-child:not(h3) {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
    }
`

export const Paragraph: React.FC<IParagraphProps> = (props) => {
    return <Block dangerouslySetInnerHTML={{ __html: props.text }}></Block>
}