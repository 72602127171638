import * as React from 'react'
import styled from 'styled-components'
import { gql, useQuery } from '@apollo/client'

// Components
import { Icons } from '../Components';
import { ContentContainer } from '../Components/Common/ContentContainer'
import { BrandedTitle } from '../Components/Common/BrandedTitle'
import { HeroBanner } from '../Components/Common/HeroBanner'
import SupplierSlider from '../Components/Common/SupplierSlider'
import ServicesGrid from '../Components/Common/ServicesGrid'
import WeAreCertified from '../Components/Common/WeAreCertified'
import ContactCta from '../Components/Sitewide/ContactUs/ContactCta'
// Components
import { Loading } from '../Components/Common/Loading'
import { Error } from '../Components/Common/404'

// Overlay
import overlay from '@/src/images/defaultOverlay-wide.png'
import { Links } from '../Components/Common/Styles/Typography';
import { Buttons } from '../Components/Common/Styles/Interactions';
import { getCorrectSolutionUrl } from '../helpers/urls';
import { graphql, useStaticQuery } from 'gatsby';

// const SEARCH_QUERY = gql`
//     query Page($id: ID!) {
//         page(idType: URI, id: $id) {
// 			pageHomeCustomFields {
//                 solutions {
//                     title
//                     excerpt
//                     backgroundImage {
//                         sourceUrl
//                     }
//                     repeater {
//                         title
//                         image {
//                             sourceUrl
//                         }
//                         link {
//                             text
//                             href {
//                                 ... on Page {
//                                     id
//                                     uri
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 whyUs {
//                     title
//                     excerpt
//                     backgroundImage {
//                         sourceUrl
//                     }
//                     repeater {
//                         title
//                         text
//                     }
//                 }
// 			}
//         }
//     }
// `;

const IndexPage: React.FC = () => {
	const {data}  = useStaticQuery(graphql`
    query {
        data: wordpress {
            page(idType: URI, id: "home") {
			    pageHomeCustomFields {
					solutions {
					  title
					  excerpt
					  backgroundImage {
						sourceUrl
					  }
					  repeater {
						title
						image {
						  sourceUrl
						}
						link {
						  text
						  href {
							... on WpGql_Page {
							  id
							  uri
							}
						  }
						}
					  }
					}
					whyUs {
					  title
					  excerpt
					  backgroundImage {
						sourceUrl
					  }
					  repeater {
						title
						text
					  }
					}
				}
            }
        }
    }
  `);
  
    // const { loading, error, data  }  = useQuery(SEARCH_QUERY, {
    //     fetchPolicy: "no-cache",
    //     variables: { id: 'home' }
    // });

    // if (loading) return <Loading />;
	// if (error) return <Error />;

	return (
		<>
			<HeroBanner pageSlug="home" />
            {
                data?.page?.pageHomeCustomFields?.solutions && (
                    <SolutionsSection backgroundUrl={ data?.page?.pageHomeCustomFields?.solutions?.backgroundImage?.sourceUrl || '' }>
                        <ContentContainer>
                            { data?.page?.pageHomeCustomFields?.solutions?.title && (
                                <BrandedTitle>{ data.page.pageHomeCustomFields.solutions.title }</BrandedTitle>
                            )}
                            { data?.page?.pageHomeCustomFields?.solutions?.excerpt && (
                                <h3>{ data.page.pageHomeCustomFields.solutions.excerpt }</h3>
                            )}
                            <div>
                            {
                                data?.page?.pageHomeCustomFields?.solutions?.repeater && (
                                    data.page.pageHomeCustomFields.solutions.repeater.map((solution, index) => {
										const PAGES_RELATING_TO_SOLUTIONS = ['/solutions/products/', '/solutions/services/']

										const uri = solution.link.href.uri
										let url = PAGES_RELATING_TO_SOLUTIONS.includes(uri) ? `/solutions#${getCorrectSolutionUrl(uri)}` : uri

										return (
											<SolutionsCard backgroundUrl={ solution?.image?.sourceUrl || '' } key={index}>
                                            <div>
                                                { solution?.title && (
                                                    <h4>{solution.title}</h4>
                                                )}
                                                { solution?.link && (
                                                    <Links.Link
                                                        text={ solution.link.text || ''}
                                                        link={ url || '' }
                                                        includeIcon={ true }
                                                    />
                                                )}
                                            </div>
                                        </SolutionsCard>
										)
									}
                                )
							)}
                            </div>
                        </ContentContainer>
                    </SolutionsSection>
                )
            }

			<SupplierSlider />

			<ServicesGrid title={ 'Services' }/>

			<ContactUsSection>
				<ContentContainer>
					<ContactCta
						title= { 'Contact Us' }
						summary={ 'Request a call with one of our experienced team and we’ll be in touch shortly to discuss your requirements.' }
					/>
				</ContentContainer>
			</ContactUsSection>

			<WhyUsSection backgroundUrl={ data?.page?.pageHomeCustomFields?.whyUs?.backgroundImage?.sourceUrl || '' }>
                { data?.page?.pageHomeCustomFields?.whyUs?.title && (
                    <BrandedTitle>{ data.page.pageHomeCustomFields.whyUs.title }</BrandedTitle>
                )}
                { data?.page?.pageHomeCustomFields?.whyUs?.excerpt && (
                    <p>{ data.page.pageHomeCustomFields.whyUs.excerpt }</p>
                )}
				<ContentContainer>
                    {
                        data?.page?.pageHomeCustomFields?.whyUs?.repeater && (
					        <WhyUsContentLeft>
                                {
                                    data.page.pageHomeCustomFields.whyUs.repeater.map((reason, index) => (
                                        <div key={index}>
                                            { reason?.title && (
                                                <h3>
                                                    <Icons.Play width={"24px"} />
                                                    {reason.title}
                                                </h3>
                                            )}
                                            { reason?.text && (
                                                <p>{reason.text}</p>
                                            )}
                                        </div>
                                    ))
                                }
					        </WhyUsContentLeft>
                        )
                    }

					<WhyUsContentRight>
						<WeAreCertified />
					</WhyUsContentRight>
				</ContentContainer>
				<Buttons.Secondary text="Learn More" href="/why-us" target="_self" />
			</WhyUsSection>
		</>
	)
};

const SolutionsSection = styled.section<{ backgroundUrl?: string }>`
  	position: relative;
  	width: 100vw;

  	${props => props.backgroundUrl && `
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: url("${props.backgroundUrl}");
			background-position: left center;
			background-size: cover;
			background-repeat: no-repeat;
			opacity: 0.15;
		}
    `}

	.content-container {
		position: relative;
		padding-top: 4rem;
		padding-bottom: 3rem;
		background: transparent;

		> .branded-title {
			margin: 0 0 1.5rem;
			text-align: center;
			font-family: ${props => props.theme.fonts.din};
			font-size: 40px;
			font-weight: 400;
			color: ${props => props.theme.colors.nearlyNavy};
		}

		> h3 {
			margin: 0 0 3rem;
			text-align: center;
			font-family: ${props => props.theme.fonts.din};
			font-size: 20px;
			font-weight: 300;
			color: ${props => props.theme.colors.nearlyNavy};
		}

		> div {
			position: relative;
			width: 100%;
			height: max-content;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			overflow-x: scroll;
			z-index: 1;

			@media(min-width: ${props => props.theme.screensizes.medium}) {
				flex-flow: row wrap;
				align-items: flex-start;
				overflow: hidden;
			}
		}
	}
`;

const SolutionsCard = styled.article<{ backgroundUrl: string }>`
	position: relative;
	flex: 0 0 auto;
	width: 75vw;
	margin: 0 1rem 0 0;
	background-image: url('${props => props.backgroundUrl}');
	background-repeat: no-repeat;
	background-size: cover;

	@media(min-width: ${props => props.theme.screensizes.medium}) {
		width: 31%;
		margin: 0 0 1rem;
	}

	&::before {
		content: '';
		display: block;
		width: 100%;
		padding-bottom: 120%;
	}

	> div {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		padding: 2rem 1.5rem;
		background: ${props => props.theme.colors.opaqueOldJeans};

		> h4 {
			margin-bottom: 1rem;
			font-size: 32px;
			font-weight: 300;
			font-family: ${props => props.theme.fonts.din};
			color: white;
		}

		> a {
			font-size: 16px;
			font-weight: 600;
			font-family: ${props => props.theme.fonts.din};
			color: white;
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: center;
			
			> svg {
				flex: 0 0 auto;
				margin-left: 10px;
			}
		}
	}
`;

const ContactUsSection = styled.section`
	width: 100vw;
	height: max-content;
	background-color: ${props => props.theme.colors.nearlyNavy};
	background-image: url("${overlay}");
	background-size: cover;
	background-position: right center;

	.content-container {
		padding: 4rem 0;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;

		h3, p {
			text-align: center;
		}

		h3 {
			font-size: 32px;
			text-align: center;
		}

		p {
			font-size: 20px;
			line-height: 24px;
			font-weight: 300;
			text-align: center;
			padding: 0 0 24px;
			max-width: initial;

			@media(min-width: ${props => props.theme.screensizes.medium}) {
				width: 75%;
				margin: auto;
			}
		}

		a {
			margin: auto;

			@media(min-width: ${props => props.theme.screensizes.medium}) {
				max-width: 150px;
			}
		}
	}
`;

const WhyUsSection = styled.section<{ backgroundUrl: string }>`
	position: relative;
	width: 100vw;
	height: max-content;
	padding: 3rem 0;
	${props => !!props.backgroundUrl && `
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: url("${props.backgroundUrl}");
			background-size: cover;
			background-position: center center;
			opacity: 0.15;
			z-index: -1;
		}
	`}

    > .branded-title {
        margin: 0 0 1.5rem;
        text-align: center;
        font-family: ${props => props.theme.fonts.din};
        font-size: 40px;
        font-weight: 400;
        color: ${props => props.theme.colors.nearlyNavy};
    }

    > p {
        margin: 0 auto 1.5rem;
        text-align: center;
        font-family: ${props => props.theme.fonts.din};
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        color: ${props => props.theme.colors.nearlyNavy};

        @media(min-width: ${props => props.theme.screensizes.medium}) {
            width: 45%;
            font-size: 20px;
            line-height: 24px;
        }
    }

	> .content-container {
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		align-items: center;

		@media(min-width: ${props => props.theme.screensizes.medium}) {
			flex-flow: row nowrap;
		}
	}
	[class^="Secondary__Button"] {
		margin: 50px auto;
	}
`;

const WhyUsContentLeft = styled.div`
	flex: 0 0 auto;
	width: 100%;
	height: max-content;
	display: flex;
	flex-flow: column nowrap;
	align-self: flex-start;
    

	@media(min-width: ${props => props.theme.screensizes.medium}) {
		flex: 0 1 auto;
		width: 55%;
		height: unset;
		justify-content: space-between;
	}

	> div {
		flex: 0 0 auto;
		width: 100%;
		height: max-content;
		margin-bottom: 16px;
		padding: 1.5rem;
		background-color: ${props => props.theme.colors.nearlyNavy};

		> h3 {
			display: flex;
   			align-items: center;
			margin-bottom: 0.75rem;
			font-size: 24px;
			font-weight: 600;
			font-family: ${props => props.theme.fonts.dinNarrow};
			color: ${props => props.theme.colors.meaningfulMarigold};
			text-transform: uppercase;
			text-align: left;
			svg  {
				margin-right: 5px;
			}
		}

		> p {
			font-size: 16px;
			font-weight: 300;
			font-family: ${props => props.theme.fonts.din};
			color: white;
		}
	}
`;

const WhyUsContentRight = styled.div`
	flex: 0 0 auto;
	width: 100%;
	height: max-content;
	background-color: white;
	border: 1px solid ${props => props.theme.colors.almostWhite};

	@media(min-width: ${props => props.theme.screensizes.medium}) {
		flex: 0 1 auto;
		width: 40%;
	}

	> h3 {
		margin-bottom: 0.75rem;
		font-family: ${props => props.theme.fonts.dinNarrow};
		font-size: 24px;
		font-weight: 700;
		color: ${props => props.theme.colors.nearlyNavy};
		text-align: center;
		text-transform: uppercase;
	}

	> p {
		margin-bottom: 1.5rem;
		font-family: ${props => props.theme.fonts.din};
		font-size: 16px;
		font-weight: 300;
		color: ${props => props.theme.colors.nearlyNavy};
		text-align: center;
	}

	> div {
		max-width: initial;
	}
`;

export default IndexPage;
