import * as React from 'react';
import styled from 'styled-components'
import { ILinkProps } from './types';
import { Icons } from '@/src/Components';

const L = styled.a`
    color: ${ props => props.theme.colors.nearlyNavy };

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }
`

export const Link: React.FC<ILinkProps> = (props) => {
    const selectedIcon = props?.icon || 'Play'
    const Icon = Icons[selectedIcon as string]

    return <L href={ props.link }>{ props.text } { props.includeIcon && ( <Icon width={'20px'} /> )}</L>
}