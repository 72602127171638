import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import { MasonryCard, MasonryGrid } from '../../Components/Common/Masonry';

interface IServicesGridProps {
    support?:boolean
    title?:string
    backgroundColor?:string
}


const ServicesGrid: React.FC<IServicesGridProps> = (props) => {

    const { services } = useStaticQuery(
        graphql`
            query {
                services: wordpress {
                    services(first: 7) {
                        edges {
                            node {
                                title
                                uri
                                slug
                                singleServiceFields {
                                    isdoublewidth
                                }
                                featuredImage {
                                    node {
                                    sourceUrl(size: LARGE)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );
    const servicesArr = services?.services?.edges
    return (
     
        <MasonryGrid title={props?.title || '' } backgroundColor={ props?.backgroundColor || '' } >
            { 
                servicesArr.map((service, index) => {
                    const imgUrl = service?.node?.featuredImage?.node?.sourceUrl;
                    return (
                        <MasonryCard
                            isDoubleWidth={service?.node?.singleServiceFields?.isdoublewidth}
                            backgroundImage={imgUrl}
                            title={service?.node?.title}
                            linkUrl={service?.node?.uri}
                            key={index}
                        />
                    )
                
                })
            }
        </MasonryGrid>

    )
}


export default ServicesGrid;
