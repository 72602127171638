import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby';
import CheckCircle from '../../images/icons/check-circle.svg'

interface IListItems {
    text?: string;
}

interface ICustomListProps {
    listItems?: IListItems[];
    title?:string;
    size?:number
}

const StyledListWithCheckMark = styled.li`
    display:flex;
    align-items:center;
    margin-bottom: 12px;

    svg {
        margin-right: 8px;
    }
`;

export const StyledListWithMarker = styled.li`
    background: #F3F3F5;
    border-left: 4px solid  var(--meaningfulMarigold);
    padding: 15px;
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 300;

    &:last-child {
        margin-bottom: 0px;
    }
`;


export const StyledCategoryTabs = styled.div<{listItems?:any}>`
    display: flex;
    margin-top: 30px;
    margin-bottom: 50px;
    flex-direction: column;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        flex-direction: row;
        align-items: center;
    }

    h3 {
        color: var(--mobyDickWhite);
        margin-bottom: 20px;
        margin-right: 10px;
        font-size: 20px;
        text-transform: uppercase;
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            margin-bottom: 10px;
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
    }
`;

const StledTabItem = styled.li<{isActive?:boolean}>`
    margin-right: 10px;
    margin-bottom: 35px;
    @media (min-width: ${props => props.theme.screensizes.medium}) {
        margin-bottom: 10px;
    }
    a.tab {
        color: var(--casperGrey);
        border: 1px solid var(--casperGrey);
        font-weight:400;
        font-size: 14px;
        padding: 10px 7px;

        @media (min-width: ${props => props.theme.screensizes.medium}) {
            font-size: 18px;
            padding: 10px;
        }
        &:hover,&:active,&.active {
            color: var(--nearlyNavy);
            background-color: #777B8C;
            border: 1px solid #777B8C;
        }
    }
`;


export const ListWithCheckMark:  React.FC<ICustomListProps> = (props)  => {
    return (
       <ul>
          {
            props.listItems?.map((item,index) => (
                <StyledListWithCheckMark key={`list_item-${index}`} className="list-item">
                    <CheckCircle />
                    { item?.text }
                </StyledListWithCheckMark>
            ))
          } 
       </ul>
    )
}

export const ListWithMarker:  React.FC<ICustomListProps> = (props)  => {
    return (
       <ul>
          {
            props.listItems?.map((item,index) => (
                <StyledListWithMarker key={`list_item-${index}`} className="list-item">
                    { item?.text }
                </StyledListWithMarker>
            ))
          } 
       </ul>
    )
}

export const CategoryTabs: React.FC<ICustomListProps> = (props) => {
    function camelize(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
      }

    return (
        <StyledCategoryTabs listItems={props.listItems}>
            {props.title && (<h3>{props.title}</h3>)}
            <ul>
                {
                    props.listItems?.map((item,index) => {
                        if ( ! item?.text ) return null;

                        const formattedUrl = item.text.replace(' ', '-').toLowerCase();
                        return (
                            <StledTabItem key={`list_item-${index}`} className='tab-list-item'>
                                <Link 
                                    to={`/knowledge/${formattedUrl}`} 
                                    className='button alt tab' 
                                    activeClassName="active"
                                    state={{ 
                                        formartedTitle: camelize(item?.text.toLowerCase()),
                                        title:item?.text
                                    }}
                                >
                                    {item.text}
                                </Link>
                            </StledTabItem>
                        )
                    })
                }
            </ul>
       </StyledCategoryTabs>
    )
}
