import * as React from 'react';
import styled from 'styled-components';
import { ContentContainer } from './ContentContainer';
import { Icons } from '..';
import { BrandedTitle } from './BrandedTitle';
import { Links } from './Styles/Typography';

interface MasonryGridProps {
	title: string;
	backgroundColor?: string;
}

export const MasonryGrid: React.FC<MasonryGridProps> = (props) => {
	return (
		<MasonrySection backgroundColor={ props?.backgroundColor || 'almostWhite' } {...props?.title && { id: props.title.toLowerCase().replace(' ', '-') }} >
			{props?.title && (
				<BrandedTitle>{props.title}</BrandedTitle>
			)}
			<ContentContainer>
				{props.children}
			</ContentContainer>
		</MasonrySection>
	)
}

interface MasonryCardProps {
	backgroundImage?: string;
	isDoubleWidth?: boolean;
	title: string;
	linkText?: string;
	linkUrl: string;
}

export const MasonryCard: React.FC<MasonryCardProps> = (props) => {
	return (
		<MasonryArticle id={props.linkUrl.replace('/', '')} backgroundUrl={props.backgroundImage} isDoubleWidth={props.isDoubleWidth || false}>
			<div>
				<h4>{props.title}</h4>
				<Links.Link text={ props.linkText || 'Learn More' } link={ props.linkUrl } includeIcon={ true }/>
			</div>
		</MasonryArticle>
	)
}

const MasonrySection = styled.section<{ backgroundColor: string;}>`
	width: 100vw;
	padding-top: 4rem;
	padding-bottom: 3rem;
	background-color: ${props => props.theme.colors[props.backgroundColor]};

	> h2 {
		margin: 0 0 1.5rem;
		text-align: center;
		font-family: ${props => props.theme.fonts.din};
		font-size: 40px;
		font-weight: 400;
		color: ${props => props.theme.colors.nearlyNavy};
	}

	.content-container {
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-start;
		align-items: flex-start;

		@media(min-width: ${props => props.theme.screensizes.medium}) {
			flex-flow: row wrap;
			justify-content: space-between;
		}
	}

	.branded-title {
		margin: 0 0 1.5rem;
		font-size: 40px;
	}
`;

MasonrySection.defaultProps = {
	backgroundColor: 'almostWhite'
}

const MasonryArticle = styled.article<{ isDoubleWidth?: boolean, backgroundUrl?: string }>`
	position: relative;
	flex: 0 1 auto;
	width: 100%;
	margin: 0 0 1rem;
	overflow: hidden;
	background-color: ${props => props.theme.colors.almostWhite};
	max-height:370px;

	${props => !!props.backgroundUrl && `
		background-image: url("${props.backgroundUrl}");
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
	`}

	@media(min-width: ${props => props.theme.screensizes.medium}) {
		width: ${props => props.isDoubleWidth ? '66%' : '32%'};
		${props => !!props.backgroundUrl && `background-position: center center;`}
	}

	&::before {
		content: '';
		display: block;
		width: 100%;
		padding-bottom: 110%;

		@media(min-width: ${props => props.theme.screensizes.medium}) {
			padding-bottom: ${props => props.isDoubleWidth ? '48.5%' : '100%'};
		}
	}

  > div {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: max-content;
		padding: 24px;
		background: ${props => props.theme.colors.opaqueOldJeans};
		max-height: 155px;

		> h4 {
			margin-bottom: 1rem;
			font-size: 32px;
			font-weight: 400;
			font-family: ${props => props.theme.fonts.din};
			color: white;
		}

		> a {
			font-size: 16px;
			font-weight: 400;
			font-family: ${props => props.theme.fonts.din};
			color: white;
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: center;

			> svg {
				flex: 0 0 auto;
				margin-left: 10px;
			}
		}
  	}
`;