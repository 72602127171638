import * as React from 'react';
import styled, { useTheme } from 'styled-components'
import { BrandedTitle } from './BrandedTitle';
import Slider from 'react-slick';

// Contexts
import { GlobalContext } from '@/src/contexts/GlobalContext'

const SuppliersSection = styled.section`
  width: 100vw;
  padding-top: 4rem;
  padding-bottom: 3rem;
  background-color: white;

  > .branded-title {
    margin: 0 0 1.5rem;
    text-align: center;
    font-family: ${props => props.theme.fonts.din};
    font-size: 40px;
    font-weight: 400;
    color: ${props => props.theme.colors.nearlyNavy};
  }

  > h3 {
    margin: 0 0 3rem;
    text-align: center;
    font-family: ${props => props.theme.fonts.din};
    font-size: 20px;
    font-weight: 300;
    color: ${props => props.theme.colors.nearlyNavy};
  }

  .slick-track {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    > * {
      flex: 0 0 auto;
    }
  }

  .slick-slide-image-wrapper {
    width: 100%;

    > img {
      width: 50%;
    }
  }
`;


const SupplierSlider: React.FC<{}> = () => {
	const theme = useTheme();
	const { themeSettings } = React.useContext(GlobalContext);

	if (!themeSettings?.suppliers) return null;

	return (
		<SuppliersSection>
			<BrandedTitle>Trusted Supplier</BrandedTitle>
			<h3>We work with the biggest suppliers of gas detection equipment.</h3>
			<Slider
				slidesToShow={6}
				slidesToScroll={1}
				autoplay
				autoplaySpeed={0}
				speed={5000}
				cssEase={'linear'}
				arrows={false}
				dots={false}
				responsive={[
					{
						breakpoint: theme.screensizes.mediumValue,
						settings: {
							slidesToShow: 4
						}
					},
					{
						breakpoint: theme.screensizes.smallValue,
						settings: {
							slidesToShow: 2
						}
					}
				]}
			>
				{
					themeSettings.suppliers.map((supplier, index) => (
						<div className="slick-slide-image-wrapper" key={index}>
							<img src={ supplier.sourceUrl } />
						</div>
					))
				}
			</Slider>
		</SuppliersSection>
	)
}

export default SupplierSlider
