import React from 'react'
import styled from 'styled-components';

// Contexts
import { GlobalContext } from '@/src/contexts/GlobalContext'


const StyledCertifications = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px 48px;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        padding: 24px 0 48px;
    }
`

const StyledCertificationsList = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: row;
`
const WeAreCertifiedStyles = styled.div`
    border: 1px solid ${props => props.theme.colors.casperGrey};
    display:flex;
    flex-direction:column;
    align-items:center;
    height:457px;
    justify-content: center;
    max-width: 392px;
    margin: 0 auto;
    padding: 20px 0px;
    div {
        padding-bottom:0px;
        padding-top:0px;
    }
    h3 {
        text-transform:uppercase;
    }
    h3,p {
        text-align:center;
    }
     p.section-summary {
        font-size: 16px;
        margin-top: 30px;
        margin-bottom:0px;
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            width: 80%;
        }
     }

    ul {
        display:flex;
        justify-content:space-between;
        flex-wrap:wrap;
        margin-top: 30px;
        max-width:292px;
        li {
            border:none;
            margin-right:0px;
            flex: 0 0 calc(50% - 8px);
            width:100%;
            max-width:138px;

            img {
                max-height: 83px;
            }
        }
    }
`;
const StyledCertificationsListItem = styled.li`
    margin-right: 10px;
    padding: 5px;
    border: 1px solid ${props => props.theme.colors.casperGrey};
    max-width: 85px;
    width: 25%;

    &:last-child {
        margin-right: 0;
    }

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        margin-right: 24px;
    }

    img {
        display: block;
        width: 100%;
    }
`

export const WeAreCertified: React.FC = () => {
    const { themeSettings } = React.useContext(GlobalContext);
    if (!themeSettings?.certification) return null;

    return (
        <WeAreCertifiedStyles>
            { themeSettings?.certification?.title && (
                <h3>{ themeSettings.certification.title }</h3>
            )}
            { themeSettings?.certification?.summary && (
                <p className="section-summary">{ themeSettings.certification.summary }</p>
            )}
            <StyledCertifications>
                <StyledCertificationsList>
                    {
                        themeSettings?.certification?.certifications && (
                            themeSettings.certification.certifications.map((image, index) => (
                                <StyledCertificationsListItem key={index}>
                                    <img src={ image?.sourceUrl } />
                                </StyledCertificationsListItem>
                            ))
                        )
                    }
                </StyledCertificationsList>
            </StyledCertifications>
        </WeAreCertifiedStyles>
    )
}

export default WeAreCertified
