import * as React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { ContentContainer } from './ContentContainer';
import overlay from '@/src/images/defaultOverlay-wide.png';
import { CategoryTabs } from '../Common/Lists'
import { Buttons } from './Styles/Interactions';

type HeroBannerOptions = Pick<GatsbyTypes.WpGql_Page_Herobanneroptions, "heading" | "subHeading" | "blurb" | "buttonText" | "buttonLink" | "bulletinText" | "bulletinLink"> & { backgroundImage?: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpGql_MediaItem, "altText" | "mediaItemUrl">> | undefined; } & { backgroundVideo: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpGql_MediaItem, "mediaItemUrl" | "mimeType">> | undefined; }

interface HeroBannerProps {
    pageSlug?: string;
    data?: HeroBannerOptions;
    minHeight?: number;
    hasTabs?: boolean;
    onTabClick?: any;
}

const VideoWrapper = styled.section`
    position: relative;
    height: 500px;

    video {
        height: 500px;
        object-fit: fill;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('${overlay}');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        opacity: .5;
    }
`

const BannerWrapper = styled.section<{ backgroundImageUrl: string, minHeight: number}>`
    position: relative;
    display: flex;
    align-items: center;
    padding: 60px 0;
    width: 100vw;
    background: url('${props => props.backgroundImageUrl}'), ${props => props.theme.colors.nearlyNavy};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    min-height: ${ props => props.minHeight }px;

    @media(min-width: ${props => props.theme.screensizes.medium}) {
        height: 0;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('${overlay}');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }
`;

const ContentWrapper = styled(ContentContainer)<{ position: string }>`
    display: flex;
    flex-direction: column;
    max-height: 80%;
    z-index: 1;
    position: ${ props => props.position };
    top: ${ props => props.position !== 'initial' ? '50%' : 'initial' };
    transform: ${ props => props.position !== 'initial' ? 'translateY(-50%)' : 'initial' };

    .inner-wrapper {
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            width: 80%;
        }
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            width: 50%;
        }
    }
`;

const SubHeading = styled.h3`
    text-align: left;
    color: ${props => props.theme.colors.meaningfulMarigold};
    font-size: 20px;
    font-family: ${props => props.theme.fonts.din};
    font-weight: 600;
    text-transform: uppercase;
`;

const Heading = styled.h2`
    margin: 8px 0;
    text-align: left;
    color: white;
    font-size: 48px;
    font-family: ${props => props.theme.fonts.dinNarrow};
    font-weight: 600;
    text-transform: uppercase;
`;

const Blurb = styled.p`
    margin: 1rem 0;
    color: ${props => props.theme.colors.almostWhite};
    font-size: 20px;
    line-height: 25px;
    font-family: ${props => props.theme.fonts.din};
    font-weight: 300;
`;

const Bulletin = styled.p`
    margin: 10px 0px;
    padding: 10px 15px;
    border-left: 4px solid var(--meaningfulMarigold);
    color: var(--mobyDickWhite);
    font-size: 16px;
    margin-bottom: 5px;
    @media (min-width: ${props => props.theme.screensizes.medium}) {
        font-size: 20px;
        margin-bottom: 0px;
    }

    > a {
        color: var(--meaningfulMarigold);
        text-decoration: underline;
        text-transform: uppercase;
        font-family: var(--dinNarrow-font);
        @media (min-width: ${props => props.theme.screensizes.medium}) {
            font-size: 20px;
            margin-left: 10px;
        }
    }
`

export const HeroBanner: React.FC<HeroBannerProps> = (props) => {
    const pages = useStaticQuery<GatsbyTypes.HeroBannerQuery>(heroBannerQuery);

    let data = props.data;

    // @ts-ignore
    if ( typeof data === 'undefined' || !Object.keys(data).length ) {
        const page = (pages.wordpress?.pages?.edges?.find(edge => edge?.node?.slug === props.pageSlug))?.node?.heroBannerOptions as HeroBannerOptions | undefined;
        if (!page) return null;
        data = page;
    }

    const buttonLink = (/.+?\:\/\/.+?(\/.+?)(?:#|\?|$)/)?.exec(data?.buttonLink || '')

    const NewContent = (Component:any) => {

        interface IComponentProps {
            position: string;
        }
        return class extends React.Component<IComponentProps> {
            render() {
                const componentProps = {
                  position: this.props.position
                }

                return (
                    <Component {...componentProps} />
                )
            }
        }
    }

    const Wapper = ({ position }) => (
        <ContentWrapper position={ position }>
            <div className="inner-wrapper">
                { data?.subHeading && (
                    <SubHeading>{data.subHeading || ''}</SubHeading>
                )}
                { data?.heading && (
                    <Heading>{data.heading || ''}</Heading>
                )}
                { data?.blurb && (
                    <Blurb>{data.blurb || ''}</Blurb>
                )}
                {data?.buttonText && (
                    //@ts-ignore
                    <Buttons.Primary text={ data?.buttonText || '' } href={ buttonLink ? buttonLink[1] : buttonLink || '' } target={ '_self' }/>
                )}
            </div>

            {props?.hasTabs && (
                <CategoryTabs
                    title="Explore"
                    listItems={[ { text: 'News' }, { text: 'Case Studies' }, { text: 'Whitepapers' }, { text: 'FAQs' }, { text: 'Brochures' } ]}
                />
            )}
            {!!data?.bulletinText &&
                <Bulletin className="bulletin">
                    {data.bulletinText}{' '}

                    {!!data.bulletinLink?.url && !!data.bulletinLink.title &&
                        <a href={data.bulletinLink.url}>{data.bulletinLink.title}</a>
                    }
                </Bulletin>
            }
        </ContentWrapper>
    )

    const Content = NewContent(Wapper)

    return (
        data!.backgroundVideo ? (
            <VideoWrapper>
                <video
                    src={ data.backgroundVideo.mediaItemUrl }
                    width={ '100%' }
                    playsInline={ true }
                    autoPlay= { true }
                    muted={ true }
                    loop={ true }
                >
                    <source src={ data.backgroundVideo.mediaItemUrl } type={ data.backgroundVideo.mimeType }/>
                </video>
                <Content position={ 'absolute' }/>
            </VideoWrapper>
        ) : (
            <BannerWrapper backgroundImageUrl={data!.backgroundImage?.mediaItemUrl || ''} minHeight={props?.minHeight || 500}>
                <Content position={ 'initial' }/>
            </BannerWrapper>
        )
    );
};


const heroBannerQuery = graphql`
    query HeroBanner {
            wordpress {
                pages(first: 100) {
                    edges {
                        node {
                            slug,
                            heroBannerOptions {
                                heading,
                                subHeading,
                                blurb,
                                buttonText,
                                buttonLink,
                                bulletinText,
                                bulletinLink {
                                    url,
                                    title
                                },
                                backgroundImage {
                                    altText,
                                    mediaItemUrl
                                }
                                backgroundVideo {
                                    mediaItemUrl
                                    mimeType
                                }
                            }
                        }
                    }
                }
        }
    }
`;